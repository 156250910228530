.v-carousel {
  .carousel {
    .control-arrow {
      margin: 0;
      top: 2px;
      height: calc(100% - 10px);
      background: #cccccca1;
    }
    .thumbs-wrapper {
      margin: 0;
      .thumbs {
        padding: 0;
        margin: 0;
        .thumb {
          width: calc(100% / 3) !important;
          margin: 0;
          padding: 0;
          padding-bottom: calc(100% / 3) !important;
          position: relative;
          img{
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            object-fit: contain;
            height: 100%;
          }
        }
      }
    }
  }
}
// .slider-wrapper .slide > div{
//   width: 100%;
//   padding-bottom: 100%;
//   position: relative;
// }
// .slider-wrapper .slide > div img {
//   position: absolute;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   top: 0;
//   object-fit: contain;
//   height: 100%;
// }
.ICarousel {
  position: relative;
  img {
    opacity: 0;
  }
  .pla-img {
    img {
      opacity: 1;
    }
  }
  &.btnBottomLeft {
    .ICarousel-title {
      bottom: 10px;
      left: 10px;
    }
  }
  &.btnBottomRight {
    .ICarousel-title {
      bottom: 10px;
      right: 10px;
    }
  }
  &.btnTopLeft {
    .ICarousel-title {
      top: 10px;
      left: 10px;
    }
  }
  &.btnTopRight {
    .ICarousel-title {
      top: 10px;
      right: 10px;
    }
  }
  &.btnCenter {
    .ICarousel-title {
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
    }
  }
  .ICarousel-title {
    position: absolute;
    z-index: 111;
    padding: 5px;
    border-radius: 3px;
  }
  &.sty_1 {
    .ICarousel-title {
      bottom: 10px;
      left: 10px;
    }
  }
  &.sty_2 {
    .ICarousel-title {
      bottom: 10px;
      right: 10px;
    }
  }
  &.sty_3 {
    .ICarousel-title {
      top: 10px;
      left: 10px;
    }
  }
  &.sty_4 {
    .ICarousel-title {
      top: 10px;
      right: 10px;
    }
  }
  &.sty_5 {
    .ICarousel-title {
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
    }
  }
  &.sty_6 {
    .ICarousel-title {
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      width: 100%;
      border-radius: 0px;
    }
  }
  &.sty_7 {
    .ICarousel-title {
      bottom: 0;
      left: 0;
      right: 0;
      top: 60%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      padding: 5px;
    }
  }
}
.masonry-item {
  position: relative;
  &.sty_7 {
    .masonry-title {
      bottom: 0;
      left: 0;
      right: 0;
      top: 60%;
      position: absolute;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      padding: 5px;
    }
  }
}
